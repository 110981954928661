import { WithExtraProps } from '@components/WithExtraProps';
import React, { FC, memo } from 'react';
import parse from 'html-react-parser';

type TProps = {
  innerHtml: string;
  className?: string | null;
  $customColor?: string;
  height?: number;
  width?: number;
}

const FromHtml: FC<TProps> = ({ innerHtml, ...props }) => (
  WithExtraProps(parse(innerHtml) as React.JSX.Element, props)
);

export default memo(FromHtml);
