import { GlobalStyles } from '@src/global.styled';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { store } from './redux/store';
import { theme } from '@src/theme';
import App from './App';
import Boost from '@components/Boost/Boost';
import Deposit from '@components/Deposit/Deposit';
import Error from '@components/Error/Error';
import Friends from '@components/Friends/Friends';
import Info from '@components/Info/Info';
import Investments from '@components/Investment/Investments';
import Mining from '@components/Mining/Mining';
import Quests from '@components/Quests/Quests';
import Rating from '@components/Rating/Rating';
import React from 'react';
import Share from '@components/Share/Share';
import UnavailablePlatform from '@components/UnavailablePlatform/UnavailablePlatform';

const unavailablePlatform = [
  'weba',
  'tdesktop',
  'unknown',
];

const initTelegram = async (): Promise<boolean> => {
  if (typeof window !== 'undefined' && Telegram && Telegram.WebApp) {
    Telegram.WebApp.ready();
    Telegram.WebApp.expand();
    Telegram.WebApp.disableVerticalSwipes();
    Telegram.WebApp.disableClosingConfirmation();
    Telegram.WebApp.setHeaderColor('#0B0C11');

    return unavailablePlatform.includes(Telegram.WebApp.platform);
  }

  return true;
};

const container = document.getElementById('root')!;
const root = createRoot(container);

(async () => {
  const [isUnavailablePlatform] = await Promise.all([
    initTelegram(),
  ]);

  if (isUnavailablePlatform) {
    root.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <UnavailablePlatform />
        </ThemeProvider>
      </React.StrictMode>,
    );
  } else {
    const router = createBrowserRouter([
      {
        path: '/',
        element: <App />,
        children: [
          {
            index: true,
            Component: Mining,
          },
          {
            path: '/boost',
            Component: Boost,
          },
          {
            path: '/investments',
            Component: Investments,
          },
          {
            path: '/exchange',
            Component: Share,
          },
          {
            path: '/deposit',
            Component: Deposit,
          },
          {
            path: '/info',
            Component: Info,
          },
          {
            path: '/friends',
            Component: Friends,
          },
          {
            path: '/quests',
            Component: Quests,
          },
          {
            path: '/rating',
            Component: Rating,
          },
        ],
        errorElement: <Error />,
      },
    ]);

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <RouterProvider router={router} />
          </ThemeProvider>
        </Provider>
      </React.StrictMode>,
    );
  }
})();
