import styled, { css } from 'styled-components';

export const LevelBar = styled.div`
    color: #fff;
`;

export const ProgressHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const Level = styled.div`
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    color: #747474;

    span {
        color: #fff;
    }
`;

export const Name = styled.div`
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
`;
export const Progress = styled.div`
    height: 14px;
    background: #D9D9D926;
    border-radius: 24px;
    border: 1px solid #FFFFFF52;
`;

type TCurrentProgress = {
  $width: number;
}

export const CurrentProgress = styled.div<TCurrentProgress>`${({ $width }) => css`
    width: ${$width}%;
    height: 100%;
    border-radius: 24px;
    background: linear-gradient(90deg, #9A0E0E 0%, #FFA300 100%);
`}`;
