import styled from 'styled-components';

export const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
`;

export const Name = styled.div`
    font-size: 20px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
`;

export const NameButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #feae03;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    color: #1E202D;
`;

export const Link = styled.a`
    color: #feae03;
    text-decoration: none;
    margin-bottom: 12px;
    text-align: center;
`;

export const Privacy = styled.div`
    margin-top: auto;
    text-align: center;
`;
