import { TBuyShare, buyShare, selectBuyingShare } from '@src/redux/slices/shareSlice';
import { TShare } from '@src/app/types';
import { checkEnoughMoney, setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import Button from '@ui/Button/Button';
import DotLoader from '@ui/DotLoader/DotLoader';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Timer from '@ui/Timer/Timer';

type TProps = {
  share: TShare;
  count: number;
}

type TButtonState = {
  text: string | React.JSX.Element;
  disabled: boolean;
}

const BuyButton: FC<TProps> = ({ share, count }) => {
  const dispatch = useAppDispatch();
  const buyingShare = useSelector(selectBuyingShare);
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, share.price * count));
  const timer = useTimer(share.timeout);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: 'Купить',
    disabled: true,
  });

  const buyShareCallback = useCallback(() => {
    const fetchingDispatch = dispatch(buyShare({
      share,
      count,
    }));
    fetchingDispatch.then(response => {
      window.ym(98334776, 'reachGoal', 'BUY_SHARE', {
        order_price: share.price * count,
        currency: 'RUB',
      });
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            actionField: {
              id: new Date().getTime(),
            },
            products: [{
              id: share.id,
              name: share.name,
              price: share.price * count,
              category: 'Акции',
            }],
          },
        },
      });

      const player = (response.payload as TBuyShare)?.player;
      if (player) {
        dispatch(setPlayer(player));
      }
    });
  }, [count, share, dispatch]);

  useEffect(() => {
    if (share.timeout && timer) {
      setButtonState({
        text: <Timer timer={timer} />,
        disabled: true,
      });
    } else if (buyingShare) {
      setButtonState({
        text: <DotLoader />,
        disabled: true,
      });
    } else if (share.status === 'IN_PROCESS') {
      setButtonState({
        text: 'Посмотреть результат',
        disabled: false,
      });
    } else if (!hasEnoughMoney) {
      setButtonState({
        text: 'Недостаточно средств',
        disabled: true,
      });
    } else {
      setButtonState({
        text: 'Купить',
        disabled: false,
      });
    }
  }, [buyingShare, share.timeout, hasEnoughMoney, timer, share.status]);

  return (
    <Button
      clickCallback={buyShareCallback}
      disabled={buttonState.disabled}
      text={buttonState.text}
    />
  );
};

export default memo(BuyButton);
