import * as S from './Share.styled';
import { ShareRisk } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { selectHistory, selectShares } from '@src/redux/slices/shareSlice';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import React, { FC, memo } from 'react';
import SVG from '@ui/SVG/SVG';
import ShareCard from './ShareCard';
import ShareModal from '@components/Share/ShareModal';

const Share: FC = () => {
  const shares = useSelector(selectShares);
  const history = useSelector(selectHistory);

  return (
    <Capital title="Биржа">
      <S.SubTitle>Минимальный риск</S.SubTitle>
      <S.GridScroll>
        <S.Grid $itemCount={shares[ShareRisk.EASY].length}>
          {shares[ShareRisk.EASY].map(share => (
            <ShareCard
              key={share.id}
              share={share}
            />
          ))}
        </S.Grid>
      </S.GridScroll>
      <S.SubTitle>Средний риск</S.SubTitle>
      <S.GridScroll>
        <S.Grid $itemCount={shares[ShareRisk.NORMAL].length}>
          {shares[ShareRisk.NORMAL].map(share => (
            <ShareCard
              key={share.id}
              share={share}
            />
          ))}
        </S.Grid>
      </S.GridScroll>
      <S.SubTitle>Высокий риск</S.SubTitle>
      <S.GridScroll>
        <S.Grid $itemCount={shares[ShareRisk.HARD].length}>
          {shares[ShareRisk.HARD].map(share => (
            <ShareCard
              key={share.id}
              share={share}
            />
          ))}
        </S.Grid>
      </S.GridScroll>
      <S.HistoryBlock>
        <S.SubTitle>Последние 20 сделок</S.SubTitle>
        {history.length ? (
          <>
            {history.map(share => (
              <S.History key={share.id}>
                <S.HistoryName>
                  {share.name}
                </S.HistoryName>
                <S.HistoryResult $isSuccess={share.status === 'SUCCESS'}>
                  {share.status === 'SUCCESS' ? '+' : '-'}
                  {share.status === 'SUCCESS' ? getHumanBalance(share.income) : getHumanBalance(share.price * share.count)}
                  <SVG
                    name="coin"
                    width={16}
                    height={16}
                  />
                </S.HistoryResult>
              </S.History>
            ))}
          </>
        ) : (
          <S.HistoryMessage>Вы ещё не совершали сделок</S.HistoryMessage>
        )}
      </S.HistoryBlock>
      <ShareModal />
    </Capital>
  );
};

export default memo(Share);
