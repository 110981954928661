import React from 'react';

export function WithExtraProps<T>(complementedComponent: React.JSX.Element, extraProps: T): React.JSX.Element | null {
  return complementedComponent
    ? (
      <complementedComponent.type
        key={complementedComponent.key}
        {...complementedComponent.props}
        {...extraProps}
      />
    )
    : null;
}
