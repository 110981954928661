import * as React from 'react';
import * as S from './ToastStack.styled';
import { FC, memo } from 'react';
import { selectToasts } from '@src/redux/slices/toastSlice';
import { useSelector } from 'react-redux';
import Toast from '@components/Toast/Toast';

const ToastStack: FC = () => {
  const toasts = useSelector(selectToasts);

  return (
    <S.Stack>
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          toast={toast}
        />
      ))}
    </S.Stack>
  );
};

export default memo(ToastStack);
