import * as React from 'react';
import * as S from './PlayerBalance.styled';
import { FC, memo } from 'react';
import { selectBalanceCoins } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';

type TProps = {
  className?: string;
}

const PlayerBalance: FC<TProps> = ({ className }) => {
  const balance = useSelector(selectBalanceCoins);

  return (
    <S.Balance className={className}>
      <SVG name="coin" />
      <S.BalanceText>
        {Math.round(balance).toLocaleString()}
      </S.BalanceText>
    </S.Balance>
  );
};

export default memo(PlayerBalance);
