import { DefaultTheme } from 'styled-components';
import { formatString } from '@common/string';

const transition = '{0} .3s ease-out';

/**
 * Генерирует значение для transition из переданных CSS свойств.
 * @param {...string} values - CSS свойства.
 * @returns {string} Значение для transition.
 */
const generateTransition = (...values: Array<string>): string => values.map(value => formatString(transition, value)).join(',');

export const theme: DefaultTheme = {
  generateTransition,
};
