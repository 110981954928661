/**
 * Убирает скроллбар при открыти модального окна.
 */
export function setModalOpenStyles(): void {
  const body = document.querySelector('body');
  if (body !== null) {
    body.classList.add('modal-open');
  }
}

/**
 * Возвращает скроллбар при закрытии модального окна.
 */
export function setModalCloseStyles(): void {
  const body = document.querySelector('body');
  if (body !== null) {
    body.classList.remove('modal-open');
  }
}
