import * as React from 'react';
import * as S from './Deposit.styled';
import { FC, memo } from 'react';
import { selectDeposits } from '@src/redux/slices/depositSlice';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import DepositCard from './DepositCard';
import DepositIncome from '@components/Deposit/DepositIncome';

const Deposit: FC = () => {
  const deposits = useSelector(selectDeposits);

  return (
    <Capital title="Вклады">
      <S.Title>Размер вклада</S.Title>
      <S.GridScroll>
        <S.Grid>
          {deposits.map(deposit => (
            <DepositCard
              key={deposit.id}
              deposit={deposit}
            />
          ))}
        </S.Grid>
      </S.GridScroll>
      <DepositIncome />
    </Capital>
  );
};

export default memo(Deposit);
