import * as React from 'react';
import * as S from '@components/Deposit/Deposit.styled';
import { FC } from 'react';
import { getHumanBalance } from '@common/number';
import { selectCurrentDeposit } from '@src/redux/slices/depositSlice';
import { selectEarnPassive, selectLevel } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import InvestButton from '@components/Deposit/InvestButton';
import SVG from '@ui/SVG/SVG';

const DepositIncome: FC = () => {
  const currentDeposit = useSelector(selectCurrentDeposit);
  const earnPassive = useSelector(selectEarnPassive);
  const level = useSelector(selectLevel);

  if (currentDeposit) {
    return (
      <>
        <S.Income>
          <span>Ваш доход составит:</span>
          <S.IncomeValue>
            <SVG
              name="coin"
              width={24}
              height={24}
            />
            <span>
              {getHumanBalance(
                (Math.round(earnPassive * 24 * currentDeposit.percent) / 100) * level,
              )}
            </span>
          </S.IncomeValue>
        </S.Income>
        <InvestButton deposit={currentDeposit} />
      </>
    );
  }

  return null;
};

export default DepositIncome;
