import { TEventWithData } from './types';

/** Функция для удаления подписки на событие. */
type TRemoveListener = () => void;

/** Функция для выполнения после вызова события. */
type TCallback<TEvent> = (event: TEvent, removeListener: TRemoveListener) => void;

/**
 * Подписывается на кастомное событие.
 * @param {string} eventName - Имя события.
 * @param {TCallback} callback - Функция для выполнения.
 * @returns {TRemoveListener} Функция для удаления подписки на событие.
 */
export const addCustomEventListener = <TEvent = Event>(eventName: string, callback: TCallback<TEvent>): TRemoveListener => {
  const removeListener = (listenerToRemove: EventListener) => window.removeEventListener(eventName, listenerToRemove);
  const listener = (event: Event) => {
    callback(event as TEvent, () => removeListener(listener));
  };
  window.addEventListener(eventName, listener);
  return () => removeListener(listener);
};

/**
 * Вызывает кастомное событие.
 * @param {string} eventName - Имя события.
 * @param {Object} [data] - Параметры события.
 */
export const dispatchCustomEvent = <T>(eventName: string, data?: T): void => {
  const event = new Event(eventName) as TEventWithData<T>;
  event.data = data || ({} as T);
  window.dispatchEvent(event);
};
