import * as React from 'react';
import * as S from '@components/Quests/Quest.styled';
import { FC, memo } from 'react';
import { TTask } from '@src/app/types';
import { TaskCategory, setCurrentTask } from '@src/redux/slices/taskSlice';
import { getHumanBalance } from '@common/number';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import SVG from '@ui/SVG/SVG';

type TProps = {
  task: TTask;
  status: TaskCategory;
}

const QuestCard: FC<TProps> = ({ task, status }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Task
      onClick={() => {
        impactOccurred('soft');
        if (status !== TaskCategory.DONE) {
          dispatch(setCurrentTask(task));
        }
      }}
      $isDone={status === TaskCategory.DONE}
    >
      <S.TaskName>{task.name}</S.TaskName>
      <S.TaskReward>
        <span>+</span>
        <SVG
          name="coin"
          width={14}
          height={14}
        />
        <span>{getHumanBalance(task.reward)}</span>
      </S.TaskReward>
      {status !== TaskCategory.DONE && (
        <S.TaskButton>
          <SVG
            name="right"
            customColor="currentColor"
          />
        </S.TaskButton>
      )}
    </S.Task>
  );
};

export default memo(QuestCard);
