import Timer from '@ui/Timer/Timer';
import styled, { css } from 'styled-components';

export const CategoryHeader = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #2B2E42;
    border-radius: 24px;
    margin-bottom: 32px;
    position: sticky;
    top: 8px;
    z-index: 10;
`;

type TCategoryBtn = {
  $isActive: boolean;
}

export const CategoryBtn = styled.button<TCategoryBtn>`${({ $isActive }) => css`
    font-size: 12px;
    line-height: 1;
    padding: 8px;
    color: ${$isActive ? '#FCA100' : '#fff'};
    background-color: ${$isActive ? '#1E202D' : 'transparent'};
    border-radius: 16px;
    box-shadow: ${$isActive ? '0 2px 4px 0 #00000040 inset' : 'none'};
`}`;

export const Content = styled.div`

`;

export const EmptyMessage = styled.div`
    font-size: 16px;
    text-align: center;
`;

export const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
`;

export const Card = styled.div`${() => css`
    border-radius: 16px;
    background-color: #2B2E42;
    box-shadow: 2px 3px 10px 0 #00000059;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
`}`;

export const CardImage = styled.img`
    width: 100%;
    display: block;
    border-radius: 16px 16px 0 0;
`;

export const CardName = styled.div`
    font-size: 10px;
    font-weight: 700;
    padding: 10px 16px;
    text-align: center;
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 400;
    color: #A1A5BB;
    padding: 6px 16px;
    border-top: 1px solid #3B3F59;
    margin-top: auto;
`;

export const CardIncomeLabel = styled.div`
    margin-right: 12px;
`;

export const CardIncome = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    img {
        margin-top: 2px;
    }
`;

export const CardFooter = styled.div`
    padding: 10px 16px;
    background-color: #3B3F59;
    border-radius: 0 0 16px 16px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
`;

export const CardHeader = styled.div`
    position: relative;
`;

export const CardLevel = styled.div`
    padding: 0 8px;
    border-radius: 8px;
    background-color: #3B3F59;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    position: absolute;
    top: 8px;
    left: 8px;
`;

export const CardExpireInfo = styled(CardLevel)`
    left: unset;
    right: 8px;
`;

export const CardExpire = styled(Timer)`
    display: flex;
    padding: 2px 8px;
    border-radius: 8px;
    background-color: #3b3f59c7;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
`;

export const CardRequirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 16px;
    backdrop-filter: blur(1px);
`;

export const CardRequirementTimer = styled.div`
    margin-bottom: 12px;
`;
