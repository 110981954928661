import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > :nth-child(1) {
        animation: LoaderShine 1s infinite linear alternate;
    }

    > :nth-child(2) {
        animation: LoaderShine 1s infinite .3s linear alternate;
    }

    > :nth-child(3) {
        animation: LoaderShine 1s infinite .6s linear alternate;
    }

    @keyframes LoaderShine {
        0% {
            opacity: 1;
        }
        33% {
            opacity: .5;
        }
        66% {
            opacity: .5;
        }
        100% {
            opacity: 1;
        }
    }
`;
