import FromHtml from '@components/FromHTML';
import styled, { css } from 'styled-components';

export const SVG = styled(FromHtml)`
  ${({ $customColor }) => css`
      &, * {
        &[fill]:not([fill="none"]) {
          fill: ${$customColor};
        }

        &[stroke]:not([stroke="none"]) {
          stroke: ${$customColor};
        }
      }
  `}`;
