import { TPlayer } from '@src/app/types';

/**
 * Заменяет все вхождения указанной подстроки на новую подстроку.
 * @param {string} inputString - Исходная строка.
 * @param {string} substring - Подстрока, которую нужно заменить.
 * @param {string} newSubstring - Подстрока, на которую нужно заменить.
 * @returns {string} Строка с заменёнными подстроками.
 */
export const replaceSubstrings = (inputString: string, substring: string, newSubstring: string): string => (
  inputString?.split(substring).join(newSubstring)
);

/**
 * Форматирует строку с описателями типа {0}.
 * @param {string} inputString - Исходная строка.
 * @param {...string} values - Значения для подстановки.
 * @returns {string} Отформатированная строка.
 */
export function formatString(inputString: string, ...values: Array<string>): string {
  if (!inputString || !values.length) return inputString;
  let result = inputString;
  values.forEach((value, index) => {
    result = replaceSubstrings(result, `{${index}}`, value);
  });

  return result;
}

/**
 * Возвращает склонение числительного для числа.
 * @param {number} value - Число.
 * @param {Array<string>} words - Массив склонений.
 * @returns {string} Склонение.
 */
export function pluralize(value: number, words: Array<string>): string {
  const cases = [2, 0, 1, 1, 1, 2];
  const index = (value % 100 > 4 && value % 100 < 20) ? 2 : cases[Math.min(value % 10, 5)];

  return words[index];
}

export const ranks = [
  'Без ранга',
  'Студент',
  'Стартапер',
  'Бизнес-новичок',
  'Мечтатель',
  'Трудоголик',
  'Предприниматель',
  'Основатель',
  'Стартап-новатор',
  'Пред IPO',
  'IPO',
  'Магнат',
  'Создатель империи',
  'Визионер',
  'Олигарх',
  'Икона индустрии',
  'Титан',
];

export const rankDescriptions = [
  '',
  'Поздравляем, вы еще учитесь завязывать шнурки на пути к миллионам! Ваше образование станет фундаментом для будущих успехов.',
  'Вы начинаете свой путь в мире бизнеса. Впереди много интересных проектов и идей, которые могут изменить мир.',
  'Первый шаг сделан, теперь начинается настоящее приключение. Не бойтесь пробовать новое и учиться на своих ошибках.',
  'Ваши идеи уже начинают воплощаться в жизнь. Помните, мечты сбываются у тех, кто действует!',
  'Усердная работа это хорошо, но что насчет результатов? Надеемся, они тоже не за горами.',
  'Вы уже доказали свою способность вести бизнес. Следующий шаг - масштабирование и рост.',
  'Ваша компания начинает приносить плоды. Теперь важно сохранить темпы роста и продолжать двигаться вперед.',
  'Вы настоящий новатор в своей области. Продолжайте удивлять мир своими инновациями!',
  'Ваш стартап готов к публичному размещению акций. Время показать миру, насколько он ценен.',
  'Поздравления! Ваша компания стала публичной. Это новый этап развития и новые возможности.',
  'Вы достигли вершины успеха. Теперь ваше имя известно всем, и ваш бизнес продолжает расти.',
  'Вы построили настоящую империю. Ваши достижения вдохновляют многих людей.',
  'Вы видите будущее раньше других. Ваше видение помогает создавать новые продукты и услуги.',
  'Вы стали одним из самых влиятельных людей в мире. Ваш капитал и влияние позволяют вам менять правила игры.',
  'Ваше имя стало синонимом успеха и инноваций. Ваша репутация служит примером для многих предпринимателей.',
  'Вы поднялись выше всех возможных высот. Ваш вклад в развитие бизнеса неоценим.',
];

export const getPlayerName = (player: TPlayer): string => {
  if (player.first_name && player.last_name) {
    return `${player.last_name} ${player.first_name}`;
  }
  if (player.first_name) {
    return player.first_name;
  }
  if (player.username) {
    return player.username;
  }
  return `Игрок #${player.telegram_id}`;
};
