import * as React from 'react';
import * as S from './Modal.styled';
import { FC, memo } from 'react';
import { useHapticFeedback } from '@common/hooks';
import SVG from '@ui/SVG/SVG';

export type TModalItem = {
  label: string;
  value?: string | React.JSX.Element;
  isMoney?: boolean;
}

type TProps = {
  title?: string;
  description?: string;
  imageSrc?: string;
  items?: Array<TModalItem>;
  mainButton?: React.JSX.Element;
  secondButton?: React.JSX.Element;
  closeCallback: () => void;
}

const Modal: FC<TProps> = ({
  title,
  description,
  imageSrc,
  items = [],
  mainButton,
  secondButton,
  closeCallback,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Modal>
      <S.Body>
        <S.Close onClick={() => {
          impactOccurred('soft');
          closeCallback();
        }}
        >
          <SVG name="close" />
        </S.Close>
        <S.Content>
          {imageSrc && (
            <S.Image src={imageSrc} />
          )}
          {title && (
            <S.Title>
              {title}
            </S.Title>
          )}
          {description && (
            <S.Description>
              {description}
            </S.Description>
          )}
          {items.map(item => (
            <S.Item key={item.label}>
              <S.ItemLabel>{item.label}</S.ItemLabel>
              <S.ItemValue>
                {item.isMoney && (
                  <SVG
                    name="coin"
                    width={24}
                    height={24}
                  />
                )}
                {item.value}
              </S.ItemValue>
            </S.Item>
          ))}
          {(mainButton || secondButton) && (
            <S.Buttons>
              {mainButton}
              {secondButton}
            </S.Buttons>
          )}
        </S.Content>
      </S.Body>
    </S.Modal>
  );
};

export default memo(Modal);
