import * as S from './Skeleton.styled';
import React, { FC, memo } from 'react';

type IProps = {
  width: number;
  height: number;
  children?: React.ReactNode;
};

const Skeleton: FC<IProps> = ({ width, height, children }) => (
  <S.Skeleton
    width={width}
    height={height}
  >
    {children}
  </S.Skeleton>
);

export default memo(Skeleton);
