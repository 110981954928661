import { TToastType } from '@src/app/types';
import styled, { css } from 'styled-components';

type TToast = {
  $type: TToastType;
}

export const Toast = styled.div<TToast>`${({ $type }) => css`
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 6px 6px 16px;
    background-color: #fff;
    border-radius: 30px;
    animation: toast-appear .5s;
    pointer-events: auto;
    color: #0B0C11;
    ${$type === TToastType.SUCCESS && css`color: #00B01A;`}
    ${$type === TToastType.ERROR && css`color: #EB4D4D;`}

    @keyframes toast-appear {
        0% {
            opacity: 0;
            transform: translate(0, -2rem);
        }

        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
`}`;

export const Button = styled.button`
    margin-left: auto;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    color: #fff;
    background-color: #DFDFDF;
    border-radius: 50%;
`;
