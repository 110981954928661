import styled from 'styled-components';

export const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
`;

export const Message = styled.div`
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
`;

export const Link = styled.a`
    color: #feae03;
    text-align: center;
    text-decoration: none;
`;

export const Privacy = styled.div`
    text-align: center;
    margin-top: auto;
`;
