import * as S from './DotLoader.styled';
import React, { FC, memo } from 'react';
import SVG from '@ui/SVG/SVG';

const DotLoader: FC = () => (
  <S.Wrapper>
    <SVG name="role" />
    <SVG name="role" />
    <SVG name="role" />
  </S.Wrapper>
);

export default memo(DotLoader);
