import Main from '@layouts/Main';
import styled from 'styled-components';

export const Loader = styled(Main)`
    align-items: center;
    justify-content: center;
`;

export const Text = styled.div`
    margin-top: 32px;
`;
