import { ShareRisk } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { selectCurrentShare, setCurrentShare } from '@src/redux/slices/shareSlice';
import { useAppDispatch, useModalOpen } from '@common/hooks';
import { useSelector } from 'react-redux';
import BuyButton from './BuyButton';
import Modal, { TModalItem } from '@src/ui/Modal/Modal';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ShareCount from '@components/Share/ShareCount';

const getChance = (risk: string) => {
  if (risk === ShareRisk.EASY) {
    return 90;
  }
  if (risk === ShareRisk.NORMAL) {
    return 50;
  }
  return 20;
};

const getIncome = (risk: string, price: number, count: number) => {
  if (risk === ShareRisk.EASY) {
    return getHumanBalance(price * count * 0.15);
  }
  if (risk === ShareRisk.NORMAL) {
    return getHumanBalance(price * count * 1.2);
  }
  return getHumanBalance(price * count * 4.8);
};

const ShareModal: FC = () => {
  const dispatch = useAppDispatch();
  const currentShare = useSelector(selectCurrentShare);
  const [count, setCount] = useState(1);
  useModalOpen(!!currentShare);

  useEffect(() => {
    setCount(1);
  }, [currentShare]);

  const closeCallback = useCallback(() => dispatch(setCurrentShare(undefined)), [dispatch]);

  const items: Array<TModalItem> = useMemo(() => {
    const rawItems: Array<TModalItem> = [];
    if (currentShare) {
      rawItems.push({
        label: 'Цена акции',
        value: getHumanBalance(currentShare.price),
        isMoney: true,
      });

      if (currentShare.status === 'IN_PROCESS') {
        rawItems.push({
          label: 'Куплено акций',
          value: `${currentShare.count}`,
        }, {
          label: 'Сумма',
          value: getHumanBalance(currentShare.price * currentShare.count),
          isMoney: true,
        }, {
          label: 'Возможная прибыль',
          value: getIncome(currentShare.risk, currentShare.price, currentShare.count),
          isMoney: true,
        });
      } else {
        rawItems.push({
          label: 'Количество акций',
          value: (
            <ShareCount
              count={count}
              setCount={setCount}
              price={currentShare.price}
            />
          ),
        }, {
          label: 'Сумма',
          value: getHumanBalance(currentShare.price * count),
          isMoney: true,
        }, {
          label: 'Возможная прибыль',
          value: getIncome(currentShare.risk, currentShare.price, count),
          isMoney: true,
        });
      }

      rawItems.push({
        label: 'Шанс получить прибыль',
        value: `${getChance(currentShare.risk)}%`,
      });
    }

    return rawItems;
  }, [count, currentShare]);

  return currentShare ? (
    <Modal
      title={currentShare.name}
      description={currentShare.description}
      items={items}
      mainButton={(
        <BuyButton
          share={currentShare}
          count={count}
        />
      )}
      closeCallback={closeCallback}
    />
  ) : null;
};

export default memo(ShareModal);
