import * as React from 'react';
import * as S from '@components/Deposit/Deposit.styled';
import { FC, memo } from 'react';
import { TDeposit } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { selectCurrentDeposit, setCurrentDeposit } from '@src/redux/slices/depositSlice';
import { selectEarnPassive } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import DepositRequirement from '@components/Deposit/DepositRequirement';
import SVG from '@ui/SVG/SVG';

type TProps = {
  deposit: TDeposit;
}

const DepositCard: FC<TProps> = ({ deposit }) => {
  const [impactOccurred] = useHapticFeedback();
  const dispatch = useAppDispatch();
  const earnPassive = useSelector(selectEarnPassive);
  const currentDeposit = useSelector(selectCurrentDeposit);

  return (
    <S.VariantButton
      key={deposit.id}
      selected={currentDeposit?.id === deposit.id}
      onClick={() => {
        dispatch(setCurrentDeposit(deposit));
        impactOccurred('soft');
      }}
    >
      <DepositRequirement deposit={deposit} />
      <S.VariantButtonTitle selected={currentDeposit?.id === deposit.id}>
        <span>{deposit.name}</span> суточного дохода
      </S.VariantButtonTitle>
      <S.VariantButtonText>
        <SVG
          name="coin"
          width={14}
          height={14}
        />
        <span>{getHumanBalance(Math.round(earnPassive * 24 * deposit.percent))}</span>
      </S.VariantButtonText>
    </S.VariantButton>
  );
};

export default memo(DepositCard);
