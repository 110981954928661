/**
 * Возвращает Cookie с указанным именем.
 * @param {string} name - Имя.
 * @returns {string|undefined} Cookie.
 */
export function getCookie(name: string): string | null {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : null;
}

/**
 * Устанавливает Cookie.
 * @param {string} name - Имя.
 * @param {string} value - Значение.
 * @param {Record<string, unknown>} options - Опции.
 */
export function setCookie(name: string, value: string, options: Record<string, unknown> = {}): void {
  const newOptions: Record<string, unknown> = {
    path: '/',
    ...options,
  };

  if (newOptions.expires instanceof Date) {
    newOptions.expires = newOptions.expires?.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in newOptions) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = newOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

/**
 * Удаляет Cookie.
 * @param {string} name - Имя.
 */
export function deleteCookie(name: string): void {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export function setToken(token: string): void {
  (Telegram as TelegramAdvanced).Utils.sessionStorageSet('token', token);
}

export function getToken(): string {
  return (Telegram as TelegramAdvanced).Utils.sessionStorageGet('token');
}
