import * as React from 'react';
import * as S from '@components/Mining/Mining.styled';
import { FC, TouchEvent, memo, useCallback, useRef, useState } from 'react';
import { TPlayer } from '@src/app/types';
import { selectAvailableTaps, selectEarnPerTap, selectMaxTaps, setPlayer, tapEarn } from '@src/redux/slices/playerSlice';
import { sendRequest } from '@src/network';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import SVG from '@ui/SVG/SVG';
import TapCoin from '@src/images/tap-coin.png';

const Mining: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const dispatch = useAppDispatch();
  const earnPerTap = useSelector(selectEarnPerTap);
  const availableTaps = useSelector(selectAvailableTaps);
  const maxTaps = useSelector(selectMaxTaps);
  const [isTaping, setIsTaping] = useState(false);
  const clickCount = useRef<number>(0);
  const clickTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const tapButtonRef = useRef<HTMLButtonElement>(null);
  const animateRef = useRef<HTMLDivElement>(null);

  const onClickCallback = useCallback((event: TouchEvent) => {
    event.preventDefault();
    impactOccurred('soft');

    if (availableTaps > earnPerTap) {
      setIsTaping(false);
      dispatch(tapEarn());
      clickCount.current += 1;

      if (animateRef.current) {
        const animateElement = document.createElement('div');
        animateElement.style.top = `${event.changedTouches[0].clientY}px`;
        animateElement.style.left = `${event.changedTouches[0].clientX}px`;
        animateElement.innerHTML = `+${earnPerTap}`;
        animateRef.current.append(animateElement);

        animateElement.addEventListener('animationend', () => animateElement.remove());
      }

      if (clickTimeoutId.current) {
        clearTimeout(clickTimeoutId.current);
      }

      clickTimeoutId.current = setTimeout(() => {
        window.ym(98334776, 'reachGoal', 'MINING', {
          order_price: clickCount.current * earnPerTap,
          currency: 'RUB',
        });

        sendRequest<TPlayer>('/player/earn', {
          method: 'POST',
          body: {
            tapCount: clickCount.current,
          },
        })
          .then(response => {
            if (response) {
              dispatch(setPlayer(response));
            }
          })
          .catch(reason => console.log(reason?.message));

        clickTimeoutId.current = null;
        clickCount.current = 0;
      }, 500);
    }
  }, [dispatch, impactOccurred, availableTaps, earnPerTap]);

  return (
    <Capital title="Майнинг">
      <S.TapContainer>
        <S.TapButton
          ref={tapButtonRef}
          onTouchStart={() => setIsTaping(true)}
          onTouchEnd={onClickCallback}
          $isTaping={isTaping}
        >
          <img
            width={285}
            src={TapCoin}
            alt=""
          />
        </S.TapButton>
        <S.Animate ref={animateRef} />
        <S.TapFooter>
          <S.Energy>
            <SVG
              name="lightning"
              customColor="#FCA100"
            />
            <S.EnergyText>
              {availableTaps > 0 ? Math.floor(availableTaps) : 0} / {maxTaps}
            </S.EnergyText>
          </S.Energy>
        </S.TapFooter>
      </S.TapContainer>
    </Capital>
  );
};

export default memo(Mining);
