import { ShareRisk, TPlayer, TShare } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TSortedShares = {
  [ShareRisk.EASY]: TShare[];
  [ShareRisk.NORMAL]: TShare[];
  [ShareRisk.HARD]: TShare[];
}

type TShareState = {
  shares: TSortedShares;
  history: Array<TShare>;
  currentShare?: TShare;
  loading: boolean;
  buyingShare: boolean;
}

export type TBuyShare = {
  shares: Array<TShare>;
  history: Array<TShare>;
  player: TPlayer;
};

export type TFetchShares = {
  shares: Array<TShare>;
  history: Array<TShare>;
};

const sortShares = (shares: TShare[]) => ({
  [ShareRisk.EASY]: shares.filter(share => share.risk === ShareRisk.EASY),
  [ShareRisk.NORMAL]: shares.filter(share => share.risk === ShareRisk.NORMAL),
  [ShareRisk.HARD]: shares.filter(share => share.risk === ShareRisk.HARD),
});

export const shareSlice = createAppSlice({
  name: 'share',
  initialState: {
    shares: {
      [ShareRisk.EASY]: [],
      [ShareRisk.NORMAL]: [],
      [ShareRisk.HARD]: [],
    },
    history: [],
    loading: true,
    buyingShare: false,
  } as TShareState,
  reducers: create => ({
    setCurrentShare: create.reducer<TShare | undefined>((state, action) => {
      state.currentShare = action.payload;
    }),
    fetchShares: create.asyncThunk(
      async (_, { signal }) => sendRequest<TFetchShares>('/share', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.shares) {
            state.shares = sortShares(action.payload.shares);
          }
          if (action.payload?.history) {
            state.history = action.payload.history;
          }
          state.loading = false;
        },
      },
    ),
    buyShare: create.asyncThunk(
      async (payload: { share: TShare; count: number }, { signal }) => sendRequest<TBuyShare>('/share/buy', {
        signal,
        method: 'POST',
        body: {
          id: payload.share.id,
          count: payload.count,
        },
        showToast: true,
      }),
      {
        pending: state => {
          state.buyingShare = true;
        },
        rejected: state => {
          state.buyingShare = false;
          state.currentShare = undefined;
        },
        fulfilled: (state, action) => {
          if (action.payload?.shares) {
            state.shares = sortShares(action.payload.shares);
          }
          if (action.payload?.history) {
            state.history = action.payload.history;
          }
          state.currentShare = undefined;
          state.buyingShare = false;
        },
      },
    ),
  }),
  selectors: {
    selectShares: state => state.shares,
    selectCurrentShare: state => state.currentShare,
    selectShareLoading: state => state.loading,
    selectBuyingShare: state => state.buyingShare,
    selectHistory: state => state.history,
  },
});

export const {
  setCurrentShare,
  fetchShares,
  buyShare,
} = shareSlice.actions;

export const {
  selectShares,
  selectShareLoading,
  selectCurrentShare,
  selectBuyingShare,
  selectHistory,
} = shareSlice.selectors;

export default shareSlice.reducer;
