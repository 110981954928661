import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    z-index: 120;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Body = styled.div`
    margin-top: auto;
    background-color: #2B2E42;
    border-radius: 30px 30px 0 0;
    padding: 16px 16px 48px;
    text-align: center;
    animation: ModalFadeIn 0.3s;

    @keyframes ModalFadeIn {
        0% {
            transform: translate(0, 100%);
        }

        100% {
            transform: translate(0, 0);
        }
    }
`;

export const Close = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 16px auto;
    width: 30px;
    height: 30px;
    background-color: #1E202D;
    border-radius: 10px;
`;

export const Content = styled.div`
    width: 100%;
    padding: 0 16px;
`;

export const Image = styled.img`
    display: block;
    margin: 0 auto 16px;
    border-radius: 16px;
`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const Description = styled.div`
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 24px;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dashed #505479;
    border-bottom: 1px dashed #505479;
    width: 100%;
    padding: 16px 0;

    & + & {
        border-top: none;
    }
`;

export const ItemLabel = styled.div`
    font-size: 12px;
    font-weight: 600;
`;

export const ItemValue = styled.div`
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 16px;
`;
