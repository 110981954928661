import styled, { css } from 'styled-components';

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const GridScroll = styled.div`
    overflow-x: auto;
    margin: 0 -16px;
    padding: 0 16px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 135px);
    gap: 12px;
    width: calc(135px * 3 + 12px * 2);
`;

type TVariantButton = {
  selected: boolean;
}

export const VariantButton = styled.div<TVariantButton>`${({ selected }) => css`
    border-radius: 16px;
    color: #fff;
    background-color: #3B3F59;
    border: 1px dashed ${selected ? '#FCA100' : 'transparent'};
    box-shadow: 2px 3px 10px 0 #00000059;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
`}`;

type TVariantButtonTitle = {
  selected: boolean;
}

export const VariantButtonTitle = styled.div<TVariantButtonTitle>`${({ selected }) => css`
    background-color: #2B2E42;
    border-radius: 16px 16px 0 0;
    padding: 20px 4px 12px;
    flex: 1 1 auto;
    font-size: 10px;
    line-height: 1;
    font-weight: 400;
    text-align: center;

    span {
        color: ${selected ? '#FCA100' : '#fff'};
        display: block;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 4px;
    }
`}`;

export const VariantButtonText = styled.div`
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 10px 4px;
`;

export const Requirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 16px;
    backdrop-filter: blur(1px);
`;

export const Timer = styled.div`
    margin-bottom: 12px;
`;

export const Income = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 42px;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
`;

export const IncomeValue = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
