import * as React from 'react';
import * as S from './Share.styled';
import { FC, memo } from 'react';
import { TShare } from '@src/app/types';
import { setCurrentShare } from '@src/redux/slices/shareSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import BlankCard from '@src/images/blank-card.jpg';
import ShareRequirement from '@components/Share/ShareRequirement';

type TProps = {
  share: TShare;
}

const ShareCard: FC<TProps> = ({ share }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Share
      onClick={() => {
        impactOccurred('soft');
        dispatch(setCurrentShare(share));
      }}
    >
      <ShareRequirement share={share} />
      <img
        src={share.image || BlankCard}
        alt=""
      />
      <S.ShareName>{share.name}</S.ShareName>
    </S.Share>
  );
};

export default memo(ShareCard);
