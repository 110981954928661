import * as React from 'react';
import * as S from './Error.styled';
import { FC, memo } from 'react';
import Main from '@layouts/Main';

const Error: FC = () => (
  <Main>
    <S.Title>Ой-ёй! Возникла ошибка</S.Title>
    <S.Message>Пожалуйста, перезапустите приложение и попробуйте снова.</S.Message>
    <S.Link
      href="mailto:game@playrole.ru?subject=Вопрос по игре Роль Капитал"
      target="_blank"
      rel="noreferrer"
    >Связь с поддержкой
    </S.Link>
    <S.Privacy>© 2024, Роль</S.Privacy>
  </Main>
);

export default memo(Error);
