import styled, { css } from 'styled-components';

export const SubTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const HistoryMessage = styled.div`
    font-size: 12px;
    margin-bottom: 16px;
`;

export const GridScroll = styled.div`
    overflow-x: auto;
    margin: 0 -16px 24px;
    padding: 0 16px;
`;

type TGrid = {
  $itemCount: number;
}

export const Grid = styled.div<TGrid>`${({ $itemCount }) => css`
    display: flex;
    align-items: stretch;
    gap: 12px;
    width: calc(${$itemCount} * 135px + (${$itemCount - 1} * 12px));
`}`;

export const Share = styled.div`
    width: 135px;
    border-radius: 16px;
    background-color: #2B2E42;
    color: #fff;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    img {
        border-radius: 16px 16px 0 0;
    }
`;

export const ShareName = styled.div`
    font-size: 10px;
    font-weight: 400;
    padding: 6px 8px;
    text-align: center;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShareRequirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
    backdrop-filter: blur(1px);
`;

export const ShareRequirementTimer = styled.div`
    margin-bottom: 12px;
`;

export const HistoryBlock = styled.div`
    background-color: #2B2E42;
    border-radius: 18px;
    padding: 16px 16px 0;
`;

export const History = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    
    & + & {
        border-top: 1px dashed #3B3F59;
    }
`;

export const HistoryName = styled.div`
    font-size: 12px;
    font-weight: 400;
`;

type THistoryResult = {
  $isSuccess: boolean;
}

export const HistoryResult = styled.div<THistoryResult>`${({ $isSuccess }) => css`
    color: ${$isSuccess ? '#09CA26' : '#EB4D4D'};
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 600;
`}`;

export const Count = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

type TCountBtn = {
  $disabled: boolean;
}

export const CountBtn = styled.button<TCountBtn>`${({ $disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #feae03;
    background-color: #1E202D;
    border-radius: 10px;
    pointer-events: ${$disabled ? 'none' : 'auto'};
    opacity: ${$disabled ? '0.5' : '1'};
`}`;
