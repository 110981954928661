import * as React from 'react';
import * as S from './Timer.styled';
import { FC, memo } from 'react';
import SVG from '@ui/SVG/SVG';

type TProps = {
  timer: string;
  isAlarm?: boolean;
  className?: string;
}

const Timer: FC<TProps> = ({ timer, isAlarm = false, className }) => (
  <S.Timer className={className}>
    <SVG name={isAlarm ? 'alarm' : 'time'} />
    {timer}
  </S.Timer>
);

export default memo(Timer);
