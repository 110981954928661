import * as React from 'react';
import * as S from './Deposit.styled';
import { FC, memo } from 'react';
import { TDeposit } from '@src/app/types';
import { checkEnoughMoney, selectEarnPassive } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import { useTimer } from '@common/hooks';
import Timer from '@ui/Timer/Timer';

type TProps = {
  deposit: TDeposit;
}

const DepositRequirement: FC<TProps> = ({ deposit }) => {
  const earnPassive = useSelector(selectEarnPassive);
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, earnPassive * 24 * deposit.percent));
  const timer = useTimer(deposit.timeout_at);

  if (deposit.timeout_at && timer) {
    return (
      <S.Requirement>
        <S.Timer>Доход через:</S.Timer>
        <Timer timer={timer} />
      </S.Requirement>
    );
  }

  if (deposit.status === 'IN_PROCESS') {
    return <S.Requirement>Получить доход</S.Requirement>;
  }

  if (earnPassive < 10000) {
    return <S.Requirement>Недостаточный пассивный доход</S.Requirement>;
  }

  if (!hasEnoughMoney) {
    return <S.Requirement>Недостаточно средств</S.Requirement>;
  }

  return null;
};

export default memo(DepositRequirement);
