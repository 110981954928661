export const levelCoins = [
  0,
  25000,
  50000,
  100000,
  500000,
  1000000,
  25000000,
  50000000,
  100000000,
  500000000,
  1000000000,
  25000000000,
  50000000000,
  100000000000,
  500000000000,
  1000000000000,
  Infinity,
];

export const getProgress = (totalCoins: number, level: number): number => {
  if (totalCoins > levelCoins[level]) {
    return 100;
  }

  return Math.round((totalCoins / levelCoins[level]) * 100);
};

export const getCoinsForNextLevel = (level: number): number => levelCoins[level];

export const getHumanBalance = (coins: number): string => {
  const thresh = 1000;

  if (coins < 10000) {
    return Math.round(coins).toLocaleString();
  }

  const units = ['K', 'M', 'B', 'T'];
  let u = -1;
  const r = 10 ** 1;

  do {
    coins /= thresh;
    u += 1;
  } while (Math.round(Math.abs(coins) * r) / r >= thresh && u < units.length - 1);

  return `${coins.toFixed(1).replace('.0', '')}${units[u]}`;
};
