import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Footer = styled.div`
    padding: 12px 16px 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #2B2E42;
    border-radius: 24px 24px 0 0;
    width: 100%;
    position: sticky;
    z-index: 100;
    bottom: 0;
    box-shadow: 0 -4px 4px 0 #00000040;
    
    .modal-open & {
        z-index: 0;
    }
`;

type TLink = {
  $isActive: boolean;
}

export const Link = styled(BaseLink)<TLink>`${({ $isActive }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 14px;
    font-size: 12px;
    line-height: 1;
    color: ${$isActive ? '#FCA100' : '#fff'};
    background-color: ${$isActive ? '#1E202D' : 'transparent'};
    box-shadow: ${$isActive ? 'inset 0 2px 4px 0 #00000040' : 'none'};
    pointer-events: ${$isActive ? 'none' : 'auto'};
    border-radius: 18px;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out;
`}`;
