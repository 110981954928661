import * as React from 'react';
import * as S from './Main.styled';
import { FC, memo } from 'react';

type TProps = {
  className?: string;
  children: React.JSX.Element | React.JSX.Element[];
}

const Main: FC<TProps> = ({ className, children }) => (
  <S.Main className={className}>
    {children}
  </S.Main>
);

export default memo(Main);
