import * as React from 'react';
import { FC, memo, useCallback, useMemo } from 'react';
import { getHumanBalance } from '@common/number';
import { selectCurrentCard, setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useModalOpen, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import BlankCard from '@src/images/blank-card.jpg';
import BuyButton from '@components/Investment/BuyButton';
import Modal, { TModalItem } from '@ui/Modal/Modal';
import Timer from '@ui/Timer/Timer';

const CardModal: FC = () => {
  const dispatch = useAppDispatch();
  const currentCard = useSelector(selectCurrentCard);
  const expireTimer = useTimer(currentCard?.expired_at);
  useModalOpen(!!currentCard);

  const closeCallback = useCallback(() => {
    dispatch(setCurrentCard(undefined));
  }, [dispatch]);

  const items: Array<TModalItem> = useMemo(() => {
    const rawItems: Array<TModalItem> = [];

    if (currentCard) {
      if (!(currentCard.expired_at && !expireTimer)) {
        rawItems.push({
          label: 'Доход в час',
          value: getHumanBalance(currentCard.income),
          isMoney: true,
        }, {
          label: 'Цена',
          value: getHumanBalance(currentCard.price),
          isMoney: true,
        });

        if (expireTimer) {
          rawItems.push({
            label: 'Оставшееся время на покупку',
            value: (
              <Timer
                isAlarm
                timer={expireTimer}
              />
            ),
          });
        }
      } else {
        rawItems.push({
          label: 'Доход в час',
          value: getHumanBalance(currentCard.total_income),
          isMoney: true,
        }, {
          label: 'Время на покупку карточки было ограничено',
        });
      }
    }

    return rawItems;
  }, [currentCard, expireTimer]);

  return currentCard ? (
    <Modal
      closeCallback={closeCallback}
      imageSrc={currentCard.image ? (
        `https://api-coin.playrole.ru/images/card/720/${currentCard.image}`
      ) : BlankCard}
      title={currentCard.name}
      description={currentCard.description}
      items={items}
      mainButton={!(currentCard.expired_at && !expireTimer) ? (
        <BuyButton card={currentCard} />
      ) : undefined}
    />
  ) : null;
};

export default memo(CardModal);
