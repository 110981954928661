import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo } from 'react';
import { TCard } from '@src/app/types';
import { checkEnoughMoney } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import { useTimer } from '@common/hooks';
import Timer from '@ui/Timer/Timer';

type TProps = {
  card: TCard;
}

const CardRequirement: FC<TProps> = ({ card }) => {
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, card.price));
  const timer = useTimer(card.timeout_at);

  if (card.timeout_at && timer) {
    return (
      <S.CardRequirement>
        <S.CardRequirementTimer>Будет доступно через:</S.CardRequirementTimer>
        <Timer timer={timer} />
      </S.CardRequirement>
    );
  }

  if (card.requirement) {
    return <S.CardRequirement>{card.requirement}</S.CardRequirement>;
  }

  if (!hasEnoughMoney) {
    return <S.CardRequirement>Недостаточно средств</S.CardRequirement>;
  }

  return null;
};

export default memo(CardRequirement);
