import * as React from 'react';
import * as S from '@components/Share/Share.styled';
import { FC, memo } from 'react';
import { selectBalanceCoins } from '@src/redux/slices/playerSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';

type TProps = {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  price: number;
}

const ShareCount: FC<TProps> = ({
  count,
  setCount,
  price,
}) => {
  const [impactOccurred] = useHapticFeedback();
  const balance = useSelector(selectBalanceCoins);

  return (
    <S.Count>
      <S.CountBtn
        $disabled={count <= 1}
        onClick={() => {
          impactOccurred('soft');
          setCount(current => current - 1);
        }}
      >
        <SVG name="minus" />
      </S.CountBtn>
      <span>{count}</span>
      <S.CountBtn
        $disabled={price * (count + 1) > balance}
        onClick={() => {
          impactOccurred('soft');
          setCount(current => current + 1);
        }}
      >
        <SVG name="plus" />
      </S.CountBtn>
    </S.Count>
  );
};

export default memo(ShareCount);
