import * as React from 'react';
import { ButtonType } from '@src/app/types';
import { FC, memo, useCallback } from 'react';
import { TTaskComplete,
  completeTask,
  selectCompletingTask,
  selectCurrentTask,
  setCurrentTask } from '@src/redux/slices/taskSlice';
import { getHumanBalance } from '@common/number';
import { setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useModalOpen } from '@common/hooks';
import { useSelector } from 'react-redux';
import Button from '@ui/Button/Button';
import DotLoader from '@ui/DotLoader/DotLoader';
import Modal from '@ui/Modal/Modal';

const QuestModal: FC = () => {
  const dispatch = useAppDispatch();
  const currentTask = useSelector(selectCurrentTask);
  const completingTask = useSelector(selectCompletingTask);

  useModalOpen(!!currentTask);

  const clickCallback = useCallback(() => {
    if (currentTask) {
      const fetchingDispatch = dispatch(completeTask(currentTask));
      fetchingDispatch.then(response => {
        const player = (response.payload as TTaskComplete)?.player;
        if (player) {
          dispatch(setPlayer(player));
        }
      });
    }
  }, [currentTask, dispatch]);

  const closeCallback = useCallback(() => {
    dispatch(setCurrentTask(undefined));
  }, [dispatch]);

  return currentTask ? (
    <Modal
      title={currentTask.name}
      description={currentTask.description}
      imageSrc={currentTask.image ? (
        `https://api-coin.playrole.ru/images/task/720/${currentTask.image}`
      ) : undefined}
      items={[{
        label: 'Награда',
        value: getHumanBalance(currentTask.reward),
        isMoney: true,
      }]}
      mainButton={(
        <Button
          to={currentTask.action}
          target={currentTask.action.startsWith('/') ? '_self' : '_blank'}
          clickCallback={() => {
            if (currentTask.status === 'NEW') {
              clickCallback();
            } else {
              dispatch(setCurrentTask(undefined));
            }
          }}
          disabled={completingTask}
          text={completingTask ? <DotLoader /> : currentTask.action_name}
          type={ButtonType.LINK}
        />
      )}
      secondButton={currentTask.status === 'IN_PROCESS' ? (
        <Button
          clickCallback={clickCallback}
          disabled={completingTask}
          text={completingTask ? <DotLoader /> : 'Проверить'}
          type={ButtonType.TEXT}
        />
      ) : undefined}
      closeCallback={closeCallback}
    />
  ) : null;
};

export default memo(QuestModal);
