import styled, { css } from 'styled-components';

export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
`;

export const HeaderContent = styled.div`
    flex: 1 1 auto;
    text-align: center;
`;

export const HeaderButton = styled.button`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2B2E42;
    border-radius: 10px;
    width: 42px;
    height: 42px;
    color: #fff;
    
    &[disabled] {
        opacity: .5;
        pointer-events: none;
    }
`;

export const LevelName = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
`;

export const CoinProgress = styled.div`
    font-size: 14px;
`;

export const Description = styled.div`
    font-size: 14px;
    margin-top: 16px;
    color: #AEACAC;
    font-weight: 400;
    text-align: center;
    margin-bottom: 50px;
`;

export const Content = styled.div`

`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
`;

export const Item = styled.div`
    display: grid;
    grid-template-columns: 35px 180px 1fr;
    align-items: center;
    gap: 8px;
    background-color: #2B2E42;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 16px;
`;
export const ItemName = styled.div`
    font-size: 12px;
    line-height: 1;
`;
export const ItemBalance = styled.div`
    margin: 0 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 600;
`;
export const ItemRating = styled.div`
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1E202D;
    border-radius: 10px;
    height: 35px;
`;

type TProgress = {
  disabled: boolean;
};

export const Progress = styled.div<TProgress>`${({ disabled }) => css`
    height: 25px;
    background: #D9D9D926;
    border-radius: 24px;
    border: 1px solid #FFFFFF52;
    opacity: ${disabled ? '0' : '1'};
`}`;

type TCurrentProgress = {
  $width: number;
}

export const CurrentProgress = styled.div<TCurrentProgress>`${({ $width }) => css`
    width: ${$width}%;
    height: 100%;
    border-radius: 24px;
    background: linear-gradient(90deg, #9A0E0E 0%, #FFA300 100%);
`}`;

export const Message = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-align: center;
`;
