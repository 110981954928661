import { TCard, TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

export enum CardTab {
  NEW = 'NEW',
  UPGRADE = 'UPGRADE',
  EXPIRE = 'EXPIRE',
}

type TSortedCards = {
  [CardTab.NEW]: Array<TCard>;
  [CardTab.UPGRADE]: Array<TCard>;
  [CardTab.EXPIRE]: Array<TCard>;
}

type TCardState = {
  currentCard?: TCard;
  cards: TSortedCards;
  loading: boolean;
  buyingCard: boolean;
}

export type TBuyCard = {
  cards: Array<TCard>;
  player: TPlayer;
}

type TFetchCategories = {
  cards: Array<TCard>;
}

const sortCards = (cards: TCard[]): TSortedCards => ({
  [CardTab.NEW]: cards.filter(card => card.level === 0),
  [CardTab.UPGRADE]: cards.filter(card => card.level !== 0).sort((a, b) => (
    new Date(b.buy_at).getTime() - new Date(a.buy_at).getTime()
  )),
  [CardTab.EXPIRE]: cards.filter(card => card.level === 0 && card.is_unavailable),
});

export const cardSlice = createAppSlice({
  name: 'card',
  initialState: {
    currentCard: undefined,
    cards: {
      [CardTab.NEW]: [],
      [CardTab.UPGRADE]: [],
      [CardTab.EXPIRE]: [],
    },
    loading: true,
    buyingCard: false,
  } as TCardState,
  reducers: create => ({
    setCurrentCard: create.reducer<TCard | undefined>((state, action) => {
      if (action.payload) {
        window.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            detail: {
              products: [
                {
                  id: action.payload.id,
                  name: action.payload.name,
                  price: action.payload.price,
                  variant: action.payload.level,
                },
              ],
            },
          },
        });
      }
      state.currentCard = action.payload;
    }),
    fetchCards: create.asyncThunk(
      async (_, { signal }) => sendRequest<TFetchCategories>('/card', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.cards) {
            state.cards = sortCards(action.payload.cards);
          }
          state.loading = false;
        },
      },
    ),
    buyCard: create.asyncThunk(
      async (buyingCard: TCard, { signal }) => {
        window.ym(98334776, 'reachGoal', 'CARD_BUY', {
          order_price: buyingCard.price,
          currency: 'RUB',
        });
        window.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            purchase: {
              actionField: {
                id: new Date().getTime(),
              },
              products: [
                {
                  id: buyingCard.id,
                  name: buyingCard.name,
                  price: buyingCard.price,
                  variant: buyingCard.level,
                },
              ],
            },
          },
        });

        return sendRequest<TBuyCard>('/card/buy', {
          signal,
          method: 'POST',
          body: {
            id: buyingCard.id,
          },
          showToast: true,
        });
      },
      {
        pending: state => {
          state.buyingCard = true;
        },
        rejected: state => {
          state.currentCard = undefined;
          state.buyingCard = false;
        },
        fulfilled: (state, action) => {
          if (action.payload?.cards) {
            state.cards = sortCards(action.payload.cards);
          }
          state.currentCard = undefined;
          state.buyingCard = false;
        },
      },
    ),
  }),
  selectors: {
    selectCurrentCard: state => state.currentCard,
    selectCards: state => state.cards,
    selectCardsLoading: state => state.loading,
    selectBuyingCard: state => state.buyingCard,
  },
});

export const {
  setCurrentCard,
  fetchCards,
  buyCard,
} = cardSlice.actions;

export const {
  selectCurrentCard,
  selectCards,
  selectCardsLoading,
  selectBuyingCard,
} = cardSlice.selectors;

export default cardSlice.reducer;
