import * as React from 'react';
import * as S from './LevelBar.styled';
import { FC, memo } from 'react';
import { ranks } from '@common/string';
import { selectLevel, selectProgress } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';

const LevelBar: FC = () => {
  const level = useSelector(selectLevel);
  const progress = useSelector(selectProgress);

  return (
    <S.LevelBar>
      <S.ProgressHeader>
        <S.Name>Уровень:</S.Name>
        <S.Level><span>{level}</span>/{ranks.length - 1}</S.Level>
      </S.ProgressHeader>
      <S.Progress>
        <S.CurrentProgress $width={progress} />
      </S.Progress>
    </S.LevelBar>
  );
};

export default memo(LevelBar);
