import * as React from 'react';
import * as S from './Quest.styled';
import { FC, memo } from 'react';
import { TaskCategory, selectTasks } from '@src/redux/slices/taskSlice';
import { useSelector } from 'react-redux';
import Main from '@layouts/Main';
import QuestCard from '@components/Quests/QuestCard';
import QuestModal from '@components/Quests/QuestModal';

const Quests: FC = () => {
  const tasks = useSelector(selectTasks);

  return (
    <Main>
      <S.Title>Заработай больше монет</S.Title>
      <S.SubTitle>Новые задания</S.SubTitle>
      {tasks[TaskCategory.NEW].length ? (
        <S.TaskGrid>
          {tasks[TaskCategory.NEW].map(task => (
            <QuestCard
              key={task.id}
              task={task}
              status={TaskCategory.NEW}
            />
          ))}
        </S.TaskGrid>
      ) : <S.Text>Новые задания скоро появятся, следи за обновлениями.</S.Text>}
      <S.SubTitle>В процессе</S.SubTitle>
      {tasks[TaskCategory.IN_PROCESS].length ? (
        <S.TaskGrid>
          {tasks[TaskCategory.IN_PROCESS].map(task => (
            <QuestCard
              key={task.id}
              task={task}
              status={TaskCategory.IN_PROCESS}
            />
          ))}
        </S.TaskGrid>
      ) : <S.Text>Здесь будет список заданий, которые ты выполняешь.</S.Text>}
      <S.SubTitle>Выполненные</S.SubTitle>
      {tasks[TaskCategory.DONE].length ? (
        <S.TaskGrid>
          {tasks[TaskCategory.DONE].map(task => (
            <QuestCard
              key={task.id}
              task={task}
              status={TaskCategory.DONE}
            />
          ))}
        </S.TaskGrid>
      ) : <S.Text>Здесь будет список заданий, которые ты выполнил.</S.Text>}
      <QuestModal />
    </Main>
  );
};

export default memo(Quests);
