import { NavLink } from 'react-router-dom';
import Background from '@src/images/bg.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const Header = styled.div`
    padding: 12px 16px 32px;
    position: sticky;
    top: 0;
`;

export const HeaderGrid = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr 24px;
    gap: 12px;
`;

export const InfoButton = styled(NavLink)`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
`;

export const Income = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background: #FFFFFF0D;
    border-radius: 24px;
    margin-top: 12px;
`;

export const IncomeLabel = styled.div`
    font-size: 12px;
    line-height: 1;
    margin-right: 8px;
`;

export const IncomeValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    margin-left: 4px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: 32px 32px 0 0;
    background-color: #1E202D;
    padding: 16px 16px 64px;
    border-top: 2px solid #65450D;
    box-shadow: 0 55px 56px 50px #F29E099E;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    margin-bottom: -48px;
    position: relative;
    z-index: 10;
`;

export const Navigation = styled.div`
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    width: 100%;
`;

export const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    text-align: center;
    height: 62px;
    background-color: #2B2E42;
    box-shadow: 2px 2px 4px 0 #00000040;
    border-radius: 18px;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, box-shadow .2s ease-in-out;

    &.active {
        color: #FCA100;
        box-shadow: inset 0 2px 4px 0 #00000040;
        pointer-events: none;
        background-color: #1E202D;
    }
`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 24px;
`;
