import { TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TReferralSliceState = {
  referrals: Array<TPlayer>;
  loading: boolean;
}

export const referralSlice = createAppSlice({
  name: 'referral',
  initialState: {
    referrals: [],
    loading: true,
  } as TReferralSliceState,
  reducers: create => ({
    fetchReferrals: create.asyncThunk(
      async (_, { signal }) => sendRequest<Array<TPlayer>>('/referral', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.referrals = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectReferrals: state => state.referrals,
    selectReferralsLoading: state => state.loading,
  },
});

export const {
  fetchReferrals,
} = referralSlice.actions;

export const {
  selectReferrals,
  selectReferralsLoading,
} = referralSlice.selectors;

export default referralSlice.reducer;
