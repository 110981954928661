import styled, { css } from 'styled-components';

export const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 42px;
    text-align: center;
`;

export const SubTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const Text = styled.div`
    font-size: 12px;
    margin-bottom: 24px;
`;

export const TaskGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1 1 auto;
    
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

type TTask = {
  $isDone?: boolean;
};

export const Task = styled.div<TTask>`${({ $isDone = false }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 100px 35px;
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 0 #00000059;
    background-color: #2B2E42;
    opacity: ${$isDone ? '0.8' : '1'};
`}`;

export const TaskName = styled.div`
    font-size: 12px;
    font-weight: 500;
`;

export const TaskReward = styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const TaskButton = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FCA100;
    background-color: #1E202D;
    border-radius: 10px;
`;
