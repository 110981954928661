import { TToast } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { nanoid } from '@reduxjs/toolkit';
import { store } from '@src/redux/store';

type TToastState = {
  toasts: Array<TToast>;
}

export const toastSlice = createAppSlice({
  name: 'toast',
  initialState: {
    toasts: [],
  } as TToastState,
  reducers: create => ({
    addToast: create.reducer<TToast>((state, action) => {
      state.toasts.unshift(action.payload);
    }),
    removeToast: create.reducer<string>((state, action) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
    }),
  }),
  selectors: {
    selectToasts: state => state.toasts,
  },
});

export const addToastAction = (toast: Omit<TToast, 'id'>): string => {
  const id = nanoid();
  store.dispatch(toastSlice.actions.addToast({
    id,
    ...toast,
  }));
  return id;
};

export const removeToastAction = (id: string): void => {
  store.dispatch(toastSlice.actions.removeToast(id));
};

export const { removeToast } = toastSlice.actions;
export const { selectToasts } = toastSlice.selectors;

export default toastSlice.reducer;
