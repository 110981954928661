import Background from '@src/images/bg.svg';
import styled from 'styled-components';

export const Main = styled.div`
    padding: 16px;
    flex: 1 1 auto;
    background-color: #0B0C11;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
