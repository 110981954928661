import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { CardTab, selectCards } from '@src/redux/slices/cardSlice';
import { FC, memo, useState } from 'react';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import Card from '@components/Investment/Card';
import CardModal from '@components/Investment/CardModal';

function getEmptyMessage(activeTab: CardTab) {
  if (activeTab === CardTab.NEW) {
    return 'Новые карточки скоро появятся, следи за обновлениями.';
  }
  if (activeTab === CardTab.UPGRADE) {
    return 'Покупай карточки для увеличения пассивного дохода и будь в топе рейтинга.';
  }
  return 'Так держать! Ты не упустил ни одной карточки.';
}

const Investments: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const cards = useSelector(selectCards);
  const [activeTab, setActiveTab] = useState<CardTab>(CardTab.UPGRADE);

  return (
    <Capital title="Пассивный доход">
      <>
        <S.CategoryHeader>
          <S.CategoryBtn
            $isActive={activeTab === CardTab.UPGRADE}
            onClick={() => {
              impactOccurred('soft');
              setActiveTab(CardTab.UPGRADE);
            }}
          >
            Купленные
          </S.CategoryBtn>
          <S.CategoryBtn
            $isActive={activeTab === CardTab.NEW}
            onClick={() => {
              impactOccurred('soft');
              setActiveTab(CardTab.NEW);
            }}
          >
            Новые
          </S.CategoryBtn>
          <S.CategoryBtn
            $isActive={activeTab === CardTab.EXPIRE}
            onClick={() => {
              impactOccurred('soft');
              setActiveTab(CardTab.EXPIRE);
            }}
          >
            Упущенные
          </S.CategoryBtn>
        </S.CategoryHeader>
        <S.Content>
          {!cards[activeTab].length && (
            <S.EmptyMessage>{getEmptyMessage(activeTab)}</S.EmptyMessage>
          )}
          <S.CategoryGrid>
            {cards[activeTab].map(card => (
              <Card
                key={card.id}
                card={card}
              />
            ))}
          </S.CategoryGrid>
        </S.Content>
        <CardModal />
      </>
    </Capital>
  );
};

export default memo(Investments);
