import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo } from 'react';
import { TCard } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useHapticFeedback, useTimer } from '@common/hooks';
import BlankCard from '@src/images/blank-card.jpg';
import CardRequirement from '@components/Investment/CardRequirement';
import SVG from '@ui/SVG/SVG';

type TProps = {
  card: TCard;
}

const Card: FC<TProps> = ({ card }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const expireTimer = useTimer(card.expired_at);

  return (
    <S.Card
      onClick={() => {
        impactOccurred('soft');
        dispatch(setCurrentCard(card));
      }}
    >
      {!(card.expired_at && !expireTimer) && (
        <CardRequirement card={card} />
      )}
      <S.CardHeader>
        {card.level > 0 && (
          <S.CardLevel>lvl {card.level}</S.CardLevel>
        )}
        {card.expired_at && (
          <S.CardExpireInfo>временная</S.CardExpireInfo>
        )}
        {card.image
          ? (
            <S.CardImage
              src={`https://api-coin.playrole.ru/images/card/360/${card.image}`}
              srcSet={`https://api-coin.playrole.ru/images/card/720/${card.image} x2`}
            />
          )
          : <S.CardImage src={BlankCard} />}
        {expireTimer && (
          <S.CardExpire
            isAlarm
            timer={expireTimer}
          />
        )}
      </S.CardHeader>
      <S.CardName>{card.name}</S.CardName>
      <S.CardContent>
        <S.CardIncomeLabel>Доход в&nbsp;час</S.CardIncomeLabel>
        <S.CardIncome>
          {card.level === 0 && '+'}
          <SVG
            name="coin"
            width={14}
            height={14}
          />
          {card.level === 0 ? (
            <span>{getHumanBalance(card.income)}</span>
          ) : (
            <span>{getHumanBalance(card.total_income)}</span>
          )}
        </S.CardIncome>
      </S.CardContent>
      {!(card.expired_at && !expireTimer)
        ? (
          <S.CardFooter>
            {card.level === 0 ? 'Купить' : 'Улучшить'}
          </S.CardFooter>
        )
        : (
          <S.CardFooter>
            Куплено
          </S.CardFooter>
        )}
    </S.Card>
  );
};

export default memo(Card);
