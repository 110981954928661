import { TBuyCard, buyCard, selectBuyingCard } from '@src/redux/slices/cardSlice';
import { TCard } from '@src/app/types';
import { checkEnoughMoney, setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import Button from '@ui/Button/Button';
import DotLoader from '@ui/DotLoader/DotLoader';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Timer from '@ui/Timer/Timer';

type TProps = {
  card: TCard;
}

type TButtonState = {
  text: string | React.JSX.Element;
  disabled: boolean;
}

const BuyButton: FC<TProps> = ({ card }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const buyingCard = useSelector(selectBuyingCard);
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, card.price));
  const timeoutTimer = useTimer(card.timeout_at);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: card.level === 0 ? 'Купить' : 'Улучшить',
    disabled: true,
  });

  const buyCardCallback = useCallback(() => {
    impactOccurred('soft');

    const fetchingDispatch = dispatch(buyCard(card));
    fetchingDispatch.then(response => {
      const player = (response.payload as TBuyCard)?.player;
      if (player) {
        dispatch(setPlayer(player));
      }
    });
  }, [card, dispatch, impactOccurred]);

  useEffect(() => {
    if (card.timeout_at && timeoutTimer) {
      setButtonState({
        text: <Timer timer={timeoutTimer} />,
        disabled: true,
      });
    } else if (card.requirement) {
      setButtonState({
        text: card.requirement,
        disabled: true,
      });
    } else if (!hasEnoughMoney) {
      setButtonState({
        text: 'Недостаточно средств',
        disabled: true,
      });
    } else if (buyingCard) {
      setButtonState({
        text: <DotLoader />,
        disabled: true,
      });
    } else {
      setButtonState({
        text: card.level === 0 ? 'Купить' : 'Улучшить',
        disabled: false,
      });
    }
  }, [buyingCard, card.level, card.requirement, card.timeout_at, hasEnoughMoney, timeoutTimer]);

  return (
    <Button
      clickCallback={buyCardCallback}
      disabled={buttonState.disabled}
      text={buttonState.text}
    />
  );
};

export default memo(BuyButton);
