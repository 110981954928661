import * as React from 'react';
import * as S from './Button.styled';
import { ButtonType } from '@src/app/types';
import { FC, memo } from 'react';
import { useHapticFeedback } from '@common/hooks';

type TProps = {
  disabled?: boolean;
  text: string | React.JSX.Element;
  clickCallback: () => void;
  type?: ButtonType;
  to?: string;
  target?: string;
}

const Button: FC<TProps> = ({
  disabled = false,
  text,
  clickCallback,
  type = ButtonType.MAIN,
  to,
  target,
}) => {
  const [impactOccurred] = useHapticFeedback();

  if (type === ButtonType.MAIN) {
    return (
      <S.Button
        onClick={() => {
          if (!disabled) {
            impactOccurred('soft');
            clickCallback();
          }
        }}
        disabled={disabled}
      >
        {text}
      </S.Button>
    );
  }

  if (type === ButtonType.LINK && to) {
    return (
      <S.LinkButton
        to={to}
        target={target}
        onClick={() => {
          if (!disabled) {
            impactOccurred('soft');
            clickCallback();
          }
        }}
        disabled={disabled}
      >
        {text}
      </S.LinkButton>
    );
  }

  if (type === ButtonType.TEXT) {
    return (
      <S.TextButton
        onClick={() => {
          if (!disabled) {
            impactOccurred('soft');
            clickCallback();
          }
        }}
        disabled={disabled}
      >
        {text}
      </S.TextButton>
    );
  }

  return null;
};

export default memo(Button);
