import * as React from 'react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { getHumanBalance } from '@common/number';
import { selectLastPassiveEarn } from '@src/redux/slices/playerSlice';
import { useModalOpen } from '@common/hooks';
import { useSelector } from 'react-redux';
import Button from '@ui/Button/Button';
import Modal from '@ui/Modal/Modal';

const EarnModal: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const lastPassiveEarn = useSelector(selectLastPassiveEarn);
  useModalOpen(isOpen);

  useEffect(() => {
    if (lastPassiveEarn > 0) {
      setIsOpen(true);
    }
  }, [lastPassiveEarn]);

  const closeCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  return isOpen ? (
    <Modal
      description="Пока вас не было, Роль Капитал приносил пассивный доход"
      items={[
        {
          label: 'Пассивный доход',
          value: getHumanBalance(lastPassiveEarn),
          isMoney: true,
        },
      ]}
      closeCallback={closeCallback}
      mainButton={(
        <Button
          text="Отлично!"
          clickCallback={closeCallback}
        />
      )}
    />
  ) : null;
};

export default memo(EarnModal);
