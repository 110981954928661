import styled from 'styled-components';

export const Stack = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    pointer-events: none;
    padding: 12px 16px;
    
    &:empty {
        display: none;
    }
`;
